var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import axios from "axios";
import * as crypto from "crypto-browserify";
import { Buffer } from "buffer";
import AES from "crypto-js/aes";
import Utf8 from "crypto-js/enc-utf8";
import { get, isString, orderBy } from "lodash";
import { cookieKeysEnum } from "../models/common.models";
import moment from "moment";
import Cookies from "universal-cookie";
import { setCurrentLocationStorage } from "../common.actions";
import { MODULES, NAVIGATION, NAVIGATIONHEADER, PAGE_CONFIG } from "../models/navigation.models";
import { CompletionIntervalList } from "../pages/WorkItemDetails/workItemDetail.model";
export var Blank_Space_Check_Regex = /^(?=.*\S).+$/;
export var Name_Regex = /^[A-Za-z]*$/;
export var NameWithWhiteSpace_Regex = /^[a-zA-Z ]*$/;
export var AlphaNumeric_Regex = /^[A-Za-z0-9]*$/;
export var Number_Regex = /^[0-9]*$/;
export var PositiveRegex = /^\d+(\.\d{1,2})?$/;
export var MinDate = "01/01/1900";
export var minDateforReport = "01/01/2017";
export var COOKIES = new Cookies();
var securityKey = "aes-256-cbc";
export var encrypt = function (text) {
    try {
        if (text) {
            text = text.toString();
            var iv = crypto.randomBytes(Number(process.env.REACT_APP_STORAGE_IV_LENGTH));
            var cipher = crypto.createCipheriv(securityKey, Buffer.from(process.env.REACT_APP_STORAGE_VALUE), iv);
            var encrypted = cipher.update(text);
            encrypted = Buffer.concat([encrypted, cipher.final()]);
            return iv.toString("hex") + ":" + encrypted.toString("hex");
        }
        return text;
    }
    catch (e) {
        console.log("Exception in Encryption", e);
    }
};
export var decrypt = function (text) {
    try {
        if (text) {
            text = text.toString();
            var textParts = text.split(":");
            if (textParts && textParts.length > 0) {
                var iv = Buffer.from(textParts.shift(), "hex");
                var encryptedText = Buffer.from(textParts.join(":"), "hex");
                var decipher = crypto.createDecipheriv(securityKey, Buffer.from(process.env.REACT_APP_STORAGE_VALUE), iv);
                var decrypted = decipher.update(encryptedText);
                decrypted = Buffer.concat([decrypted, decipher.final()]);
                return decrypted.toString();
            }
        }
        return text;
    }
    catch (e) {
        try {
            if (text) {
                var bytes = AES.decrypt(text, process.env.REACT_APP_STORAGE_VALUE);
                var decryptedData = JSON.parse(bytes.toString(Utf8));
                return decryptedData;
            }
            return text;
        }
        catch (e) {
            console.log("Exception in Decryption", e);
        }
    }
};
export var cacheManager = {
    getItem: function (key, isDecrypt) {
        if (isDecrypt === void 0) { isDecrypt = true; }
        if (isDecrypt) {
            return decrypt(window.localStorage.getItem(key));
        }
        else {
            return window.localStorage.getItem(key);
        }
    },
    setItem: function (key, value) {
        var string = isString(value) ? encrypt(value) : encrypt(JSON.stringify(value));
        window.localStorage.setItem(key, string);
    },
    removeItem: function (key) {
        window.localStorage.removeItem(key);
    },
    clearSession: function () {
        window.localStorage.clear();
    },
};
export var formatDateToMMDDYYYYHHMMSS = function (date) {
    return moment.utc(date).local().format("MM/DD/YYYY HH:mm:ss");
};
export var cookiesManager = {
    setCookie: function (key, value, isParentOrigin) {
        if (isParentOrigin === void 0) { isParentOrigin = true; }
        var _date = new Date();
        _date.setTime(_date.getTime() + 60 * 60 * 24 * 1000); // TODO : Remove Cookies in 24Hrs.
        var options = {
            path: "/",
            expires: _date,
            domain: isParentOrigin ? getDomainFromSubDomain(window.location.hostname) : window.location.hostname,
            secure: window.location.hostname === "localhost" ? false : true,
        };
        COOKIES.set(key, encrypt(value), options);
    },
    getCookie: function (key, isDecrypt, isParentOrigin) {
        if (isDecrypt === void 0) { isDecrypt = true; }
        if (isParentOrigin === void 0) { isParentOrigin = true; }
        var options = {
            path: "/",
            domain: isParentOrigin ? getDomainFromSubDomain(window.location.hostname) : window.location.hostname,
            secure: window.location.hostname === "localhost" ? false : true,
        };
        if (isDecrypt) {
            return decrypt(COOKIES.get(key, options));
        }
        else {
            return COOKIES.get(key, options);
        }
    },
    removeCookie: function (key, isParentOrigin) {
        if (isParentOrigin === void 0) { isParentOrigin = true; }
        var options = {
            path: "/",
            domain: isParentOrigin ? getDomainFromSubDomain(window.location.hostname) : window.location.hostname,
            secure: window.location.hostname === "localhost" ? false : true,
        };
        COOKIES.remove(key, options);
    },
};
export function utcDateTimeToLocalDateTime(date) {
    return moment.utc(date).local().format("MM/DD/YYYY hh:mm A");
}
export function utcDateTimeSecondToLocalDateTime(date, format) {
    if (format === void 0) { format = "MM/DD/YYYY hh:mm:ss A"; }
    return moment.utc(date).local().format(format);
}
export function utcDateTimeSecondToDate(date, format) {
    if (format === void 0) { format = "MM/DD/YYYY hh:mm:ss A"; }
    return moment.utc(date).format(format);
}
// Change format to MMM dd, yyyy
export function dateFormatToStringWithDateAndTime(date) {
    date = utcDateTimeToLocalDateTime(date);
    return moment(date).format("ll") + " at " + moment(date).format("LT");
}
export var getCookie = function (key) {
    var name = key + "=";
    var ca = document.cookie.split(";");
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) === " ") {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
};
export var getFileType = function (fileName) {
    var lastIndex = fileName.lastIndexOf(".");
    if (fileName.length > lastIndex + 1 && lastIndex > 0) {
        return fileName.substring(lastIndex + 1).toLowerCase();
    }
    return fileName;
};
export var getCurrentlyShowingItemsInGrid = function (pageNumber, pageSize, totalItems) {
    if (pageNumber > 0 && pageSize > 0 && totalItems > 0) {
        var startIndex = pageNumber * pageSize - pageSize + 1;
        var lastIndex = totalItems >= pageNumber * pageSize ? pageNumber * pageSize : totalItems;
        return "".concat(startIndex, " - ").concat(lastIndex, " of ").concat(totalItems, " items");
    }
    return "";
};
export var scrollToTopOfPage = function () {
    var scrollElement = document.getElementById("root");
    if (scrollElement) {
        scrollElement.scrollTop = 0;
    }
};
// Extract a query string value from the url
export var extractValueFromUrl = function (url, paramName) {
    var regex;
    if (url && url.indexOf(paramName) === 0) {
        regex = new RegExp(paramName + "=([^&#]*)", "i");
    }
    else {
        regex = new RegExp("[?&]" + paramName + "=([^&#]*)", "i");
    }
    var string = regex.exec(url);
    return string ? string[1] : null;
};
export var getMessage = function (messageList, message) {
    return get(messageList, message);
};
export var getSortOrderFromHeaderClick = function (event) {
    var orderType = 0; // 0 for Ascending order, 1 for descending order
    if (event.target.attributes.getNamedItem("data-order")) {
        orderType = event.target.attributes.getNamedItem("data-order").value;
    }
    else if (event.currentTarget.attributes.getNamedItem("data-order")) {
        orderType = event.currentTarget.attributes.getNamedItem("data-order").value;
    }
    else {
        orderType = event.target.parentElement.attributes.getNamedItem("data-order").value;
    }
    return orderType;
};
export var resetHeaderSortOrder = function (list) {
    list.map(function (item) {
        item.orderBy = 0;
        item.orderByIcon = 0;
        if (item.title === "Modified Date/Time") {
            item.orderBy = 0;
            item.orderByIcon = 2;
        }
        else if (item.sortKey === "WorkItemNumber") {
            item.orderBy = 0;
            item.orderByIcon = 2;
        }
    });
};
export var formatListDataForAutoSearch = function (list, column) {
    if (column === void 0) { column = null; }
    if (list && list.length > 0) {
        var result_1 = [];
        list.map(function (item) {
            result_1.push({ title: item.Title || item.text || item[column] });
        });
        return result_1;
    }
    return [];
};
export var generateUUID = function () {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
        var r = (Math.random() * 16) | 0, v = c == "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
    });
};
export var generateUUIDForAutofill = function () {
    var dt = new Date().getTime();
    var uuid = "xxxxxxxx".replace(/[xy]/g, function (c) {
        var r = (dt + Math.random() * 16) % 16 | 0;
        dt = Math.floor(dt / 16);
        return (c == "x" ? r : (r & 0x3) | 0x8).toString(16);
    });
    return uuid;
};
// Replace String at Selected Index
export var replaceAt = function (string, index, replacement) {
    return string.substr(0, index) + replacement + string.substr(index + replacement.length);
};
// Validate Params Keys while Url Comes from Request
export var validateUrlParams = function (routeUrls, currentPath) {
    currentPath = currentPath && replaceAt(currentPath, 0, " ");
    currentPath = currentPath && currentPath.trim();
    var routeUrlSplitParamsArray = routeUrls && routeUrls.split("/");
    var currentUrlSplitParamsArray = currentPath && currentPath.split("/");
    if (routeUrls.indexOf("/:") > -1 && currentPath.indexOf(routeUrlSplitParamsArray[0]) > -1 && currentPath.indexOf(routeUrlSplitParamsArray[1]) > -1) {
        for (var i = 0; i < routeUrlSplitParamsArray.length; i++) {
            if (currentPath.indexOf(routeUrlSplitParamsArray[i]) === -1) {
                routeUrlSplitParamsArray[i] = currentUrlSplitParamsArray[i];
            }
        }
        return routeUrlSplitParamsArray && routeUrlSplitParamsArray.join("/");
    }
    return routeUrls;
};
// Validate Request Url For Permission
export var validateNavigationFromUrl = function (currentPath) {
    var beautifyCurrentPath = function (url) { return url !== "" && url.replace("/", ""); };
    var navigationModal = NAVIGATIONHEADER;
    var result = [];
    if (navigationModal && navigationModal.length > 0) {
        for (var i = 0; i < navigationModal.length; i++) {
            var modulesItems = navigationModal[i][MODULES[i]];
            if (modulesItems && modulesItems.length > 0) {
                result = modulesItems.filter(function (itemsList) {
                    return currentPath === validateUrlParams(itemsList.routeUrl, currentPath);
                });
                if (result && result.length > 0) {
                    return { selectedSingleResult: result, selectedFullResult: modulesItems, currentSearchResult: result[0] };
                }
            }
        }
    }
};
export var toUTCDateAndTime = function (date, format) {
    return moment.utc(moment(date)).format(format);
};
// Filter Permission Url if Allow
export var filterPermissionUrl = function (requestUrl, method) {
    // Commented code will be used in future
    // let permissions = cacheManager.getItem("privilegeRoleModel") && JSON.parse(cacheManager.getItem("privilegeRoleModel")).length > 0 && get(JSON.parse(cacheManager.getItem("privilegeRoleModel"))[0], "permissions");
    // let isPageAuthorized =
    //   permissions &&
    //   permissions.length > 0 &&
    //   permissions.filter((items: any) => {
    //     if (method) {
    //       let hasAccess = false;
    //       if (Array.isArray(requestUrl.oslUrl)) {
    //         hasAccess = requestUrl.oslUrl.filter((x: any) => x.url == items.resource && items.methods.indexOf(x.verb) > -1 && items.action === "allow").length > 0;
    //       } else {
    //         hasAccess = items.methods.indexOf(method) > -1 && requestUrl.oslUrl === items.resource && items.action === "allow";
    //       }
    if (method === void 0) { method = undefined; }
    //       // Check for sub routes in case not found in parent
    //       if (!hasAccess) {
    //         hasAccess =
    //           items.subRoutes.length > 0 &&
    //           items.subRoutes.find((subItem: any) => {
    //             if (method) {
    //               return subItem.methods.indexOf(method) > -1 && requestUrl.oslUrl === subItem.resource && subItem.action === "allow";
    //             }
    //             return requestUrl.oslUrl === subItem.resource && subItem.action === "allow";
    //           });
    //       }
    //       return hasAccess;
    //     }
    //     return requestUrl.oslUrl === items.resource && items.action === "allow";
    //   });
    // to be changed in future
    // return isPageAuthorized;
    return true;
};
// Validate Auth or Not
export var allowPermissionForAuth = function (isPageAuthorized) {
    if (isPageAuthorized && isPageAuthorized.length > 0) {
        return true;
    }
    return false;
};
// Set Route Urls
export var routeNavigationUrl = function (page, permissionType, paramsKey) {
    if (permissionType === void 0) { permissionType = null; }
    if (paramsKey === void 0) { paramsKey = []; }
    // Permission Type Could be Null in case of Directly generaing Unique Keys on Page Params;
    var keys = permissionType !== null ? "".concat(PAGE_CONFIG.uniqueKey(page, permissionType)) : "".concat(page); // Generated Keys for Private Routes
    var setParamsOnUrlRoutes = function (selectedUrl) {
        var ParamsIndex = -1;
        var url = selectedUrl && selectedUrl.length > 0 && selectedUrl[0].routeUrl;
        url = url && url.split("/");
        if (url && url.length > 0) {
            for (var i = 0; i < url.length; i++) {
                if (url[i].indexOf(":") > -1) {
                    ParamsIndex++;
                    url[i] = paramsKey[ParamsIndex];
                }
            }
        }
        return url && url.join("/");
    };
    var moduleUrls = [];
    NAVIGATION.map(function (moduleItem, index) { return moduleItem[MODULES[index]]; }).map(function (result) {
        result.map(function (items) { return items.uniqueKey === keys && moduleUrls.push(items); });
    });
    if (moduleUrls && moduleUrls.length > 0) {
        if (paramsKey.length > 0) {
            return "/".concat(setParamsOnUrlRoutes(moduleUrls));
        }
        return "/".concat(moduleUrls[0].routeUrl);
    }
    return "/";
};
// Get Additional Privilege of Compoenent
export var getModulesAdditionalPrivilege = function (selectedUrl) {
    var additionalPermission = [];
    NAVIGATIONHEADER && NAVIGATIONHEADER.map(function (moduleItem, index) { return moduleItem[MODULES[index]] && moduleItem[MODULES[index]].map(function (subModuleItems, subIndex) { return selectedUrl.additionalPermission && selectedUrl.additionalPermission.length > 0 && selectedUrl.additionalPermission.indexOf(subModuleItems.uniqueKey) > -1 && additionalPermission.push(subModuleItems); }); });
    return additionalPermission;
};
// Validate All User Permission on Page
export var checkUserPermissionOnPage = function (allowedPermission, page, permissionType) {
    // Generated Keys for Private Routes
    var keys = PAGE_CONFIG.uniqueKey(page, permissionType);
    return allowedPermission && get(allowedPermission, [keys]);
};
// TODO : fetch azure ad code
export var azureAdFetchCode = function () {
    // Validate location get from params while called
    setCurrentLocationStorage();
    var azureTempleteURL = process.env.REACT_APP_AZURE_ROOT_URL + "/<TENENT_ID>/oauth2/authorize?response_type=code&client_id=<CLIENT_ID>&redirect_uri=<REDIRECT_URL>&state=<STATE>&resource=<RESOURCE>";
    azureTempleteURL = azureTempleteURL.replace("<TENENT_ID>", process.env.REACT_APP_TENANT_ID);
    azureTempleteURL = azureTempleteURL.replace("<CLIENT_ID>", process.env.REACT_APP_AUTH_CLIENT_ID);
    azureTempleteURL = azureTempleteURL.replace("<REDIRECT_URL>", process.env.REACT_APP_REDIRECT_URI);
    azureTempleteURL = azureTempleteURL.replace("<STATE>", "".concat(encrypt(Math.random().toString(36) + ":" + getUserAgent().name)));
    azureTempleteURL = azureTempleteURL.replace("<RESOURCE>", process.env.REACT_APP_OSL_AD_AUTH_APP_CLIENT_ID);
    return azureTempleteURL;
};
export var clearAzureAdAuthCodeAction = function () {
    // TODO : Remove forcefully Clean Session.
    cacheManager.clearSession();
    cookiesManager.removeCookie(cookieKeysEnum.APP_SESSIONID);
    var url = process.env.REACT_APP_AZURE_LOGOUT_URL + "/" + process.env.REACT_APP_TENANT_ID + "/oauth2/logout?post_logout_redirect_uri=" + process.env.REACT_APP_REDIRECT_URI + "/logout";
    return url;
};
export var validateLeftMenuNavigation = function (navigation, isParent) {
    if (isParent === void 0) { isParent = false; }
    var permissions = cacheManager.getItem("privilegeRoleModel") && JSON.parse(cacheManager.getItem("privilegeRoleModel")).length > 0 && get(JSON.parse(cacheManager.getItem("privilegeRoleModel"))[0], "permissions");
    var isPageAuthorized = false;
    if (Array.isArray(navigation.oslUrl) && isParent && permissions && permissions.length > 0) {
        isPageAuthorized = permissions.filter(function (items) { return navigation.oslUrl.filter(function (navitem) { return navitem.url == items.resource && items.methods.indexOf(navitem.verb) > -1; }).length > 0 && items.action === "allow"; }).length > 0;
    }
    else if (!isParent && permissions && permissions.length > 0) {
        isPageAuthorized = permissions.filter(function (items) { return items.methods.indexOf(navigation.oslMethod) > -1 && navigation.oslUrl === items.resource && items.action === "allow"; }).length > 0;
    }
    return isPageAuthorized;
};
// Navigation Permission Validate
export var validateNavigationPermission = function (featureFlag) {
    var NAVIGATIONRESULT = (featureFlag === null || featureFlag === void 0 ? void 0 : featureFlag.BTAppFeature) ? NAVIGATIONHEADER : NAVIGATION;
    var navResult = [];
    // Validate Parent Module Permission
    var validateParentModulePermission = function (parentItem, index) { return __awaiter(void 0, void 0, void 0, function () {
        var _a;
        return __generator(this, function (_b) {
            if (((_a = parentItem[MODULES[index]]) === null || _a === void 0 ? void 0 : _a.length) >= 0) {
                return [2 /*return*/, parentItem[MODULES[index]].filter(function (result) { return allowPermissionForAuth(filterPermissionUrl(result, result.oslMethod)); })];
            }
            return [2 /*return*/, []];
        });
    }); };
    // Validate Sub Module Permission
    var validateSubModulePermission = function (parentItem, index) {
        var _a;
        if (((_a = parentItem[MODULES[index]]) === null || _a === void 0 ? void 0 : _a.length) > 0) {
            parentItem[MODULES[index]].map(function (result) {
                if (!allowPermissionForAuth(filterPermissionUrl(result, result.oslMethod))) {
                    // Check SubModule Permission
                    // this will added in future
                    //result.isVisibleOnNavigation = false;
                }
            });
        }
    };
    // Get each navigation values.
    return new Promise(function (resolve) {
        if (NAVIGATIONRESULT && NAVIGATIONRESULT.length > 0) {
            navResult = NAVIGATIONRESULT.map(function (item, index) {
                var isParentModulePermission = validateParentModulePermission(item, index);
                isParentModulePermission.then(function (result) {
                    // Check Module Permission
                    // if (result && result.length === 0) {
                    //   item.isVisibleOnNavigation = false;
                    // }
                });
                // Check Sub Module Permission
                validateSubModulePermission(item, index);
                return item;
            });
        }
        resolve(navResult);
    });
};
export var setLoginDataToCacheManager = function (response, loginActionType) {
    var _a, _b;
    if (loginActionType === void 0) { loginActionType = null; }
    // Perform login Action on Type.
    switch (loginActionType) {
        case "DELETE": // For Delete Token
            cacheManager.removeItem("privilegeRoleModel");
            cacheManager.removeItem("userDetail");
            cacheManager.removeItem("isReactAppAuthorized");
            cacheManager.removeItem(cookieKeysEnum.accessToken);
            cookiesManager.removeCookie(cookieKeysEnum.APP_SESSIONID);
            break;
        case "LOGIN": // For Add login/Azure Ad Login Request
            cacheManager.removeItem("current-location"); // TODO : Remove current location from storage.
            cacheManager.setItem("privilegeRoleModel", response.data.privilege.privilegeRoleModel);
            cacheManager.setItem("userDetail", (_b = (_a = response.data) === null || _a === void 0 ? void 0 : _a.privilege) === null || _b === void 0 ? void 0 : _b.userDetail);
            cacheManager.setItem("isReactAppAuthorized", true);
            cacheManager.setItem(cookieKeysEnum.accessToken, response.data.accessToken);
            break;
        case "REFRESH": // For Refresh token Request Update
            if (process.env.REACT_APP_LOGIN_BY_AZURE_AD == "true") {
                if (cacheManager.getItem(cookieKeysEnum.accessToken)) {
                    cacheManager.removeItem(cookieKeysEnum.accessToken);
                }
                cacheManager.setItem(cookieKeysEnum.accessToken, response.data.accessToken);
            }
            break;
        default:
            break;
    }
};
export var isReactAppAuthorized = function () {
    if (cacheManager.getItem("isReactAppAuthorized")) {
        // VALIDATE THE TOEKN EXIST OR NOT
        if (process.env.REACT_APP_LOGIN_BY_AZURE_AD == "true" && !isTokenExistInCookies()) {
            cacheManager.clearSession(); // Check Access Token Should be Exist In Case of Azure AD
        }
        return true;
    }
    return false;
};
export var getCurrentUserEmail = function () {
    var userDetail = cacheManager.getItem("userDetail") && JSON.parse(cacheManager.getItem("userDetail"));
    if (userDetail !== null) {
        // GET CURRENT LOGGED IN USER EMAIL.
        return get(userDetail, "email");
    }
};
export var isTokenExistInCookies = function () {
    if (cookiesManager.getCookie(cookieKeysEnum["APP_SESSIONID"])) {
        return true;
    }
    return false;
};
export var getDomainFromSubDomain = function (hostname) {
    if (window.location.hostname !== "localhost") {
        var dotIndex = hostname.indexOf(".");
        return hostname.substring(dotIndex + 1);
    }
    return hostname;
};
export var noCookiesRedirectToLogin = function () {
    if (process.env.REACT_APP_LOGIN_BY_AZURE_AD == "true" && !isTokenExistInCookies()) {
        window.location.assign("/");
    }
};
export var callAPI = function (url, verb, data, callback) {
    var axioObj;
    switch (verb) {
        case "get":
            axioObj = axios.get(url, data);
            break;
        case "post":
            axioObj = axios.post(url, data);
            break;
        case "patch":
            axioObj = axios.patch(url, data);
            break;
        case "delete":
            axioObj = axios.delete(url, data);
            break;
        default:
        // DO Nothing
    }
    axioObj
        .then(function (response) {
        callback(response);
    })
        .catch(function (err) {
        callback({});
    });
};
var prevOrgName = "";
var TIMEOUT = null;
export var getMinutes = function (cTime) { return moment(cTime, "HH:mm:ss a").get("minutes"); };
export var isLocalhost = function () {
    return window.location.origin.includes("localhost");
};
// TODO : Get User Agitent (Identify request coming from browser.)
export var getUserAgent = function () {
    var test = function (regexp) { return regexp.test(window.navigator.userAgent); };
    switch (true) {
        case test(/edg/i):
            return { name: "Edge", version: "" };
        case test(/trident/i):
            return { name: "IE", version: "" };
        case test(/firefox|fxios/i):
            return { name: "Firefox", version: "" };
        case test(/opr\//i):
            return { name: "Opera", version: "" };
        case test(/ucbrowser/i):
            return { name: "UC", version: "" };
        case test(/samsungbrowser/i):
            return { name: "Samsung", version: "" };
        case test(/chrome|chromium|crios/i):
            return { name: "Chrome", version: "" };
        case test(/safari/i):
            return { name: "Safari", version: "" };
        default:
            return { name: "Other", version: "" };
    }
};
export var commaSeperator = function (value) {
    // return  value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") ;
    return new Intl.NumberFormat("en-US").format(value);
};
export var encodeStreamData = function (data) {
    var str = data &&
        data.reduce(function (a, b) {
            return a + String.fromCharCode(b);
        }, "");
    return btoa(str).replace(/.{76}(?=.)/g, "$&\n");
};
export var downloadByUrl = function (filename, url) {
    fetch(url).then(function (t) {
        return t.blob().then(function (b) {
            var a = document.createElement("a");
            a.href = URL.createObjectURL(b);
            a.setAttribute("download", filename);
            a.click();
        });
    });
};
// Generating year list
export var generateYearList = function (yearsForward) {
    if (yearsForward === void 0) { yearsForward = 2; }
    var currentYear = new Date().getFullYear();
    var yearOptionsList = [];
    var key = 0;
    var toYear = currentYear + yearsForward;
    for (var y = currentYear; y <= toYear; y++) {
        yearOptionsList.push({
            key: key,
            text: y,
            value: y,
        });
        key++;
    }
    return yearOptionsList;
};
export var setMinDate = function () {
    var minDate = new Date();
    minDate.setFullYear(minDate.getFullYear() - 3, 0, 1);
    return minDate;
};
// Get previous month with date 01
export var getDateWithPreviousMonth = function () {
    var date = new Date();
    date.setMonth(date.getMonth() - 1);
    date.setDate(1);
    return date;
};
export var downloadDocument = function (fileDataResponse, fileName) {
    var arr = fileDataResponse.content.Body.data;
    var byteArray = new Uint8Array(arr);
    var a = window.document.createElement("a");
    a.href = window.URL.createObjectURL(new Blob([byteArray], { type: fileDataResponse.content.ContentType }));
    a.download = fileName || "";
    // Append anchor to body.
    document.body.appendChild(a);
    a.click();
    // Remove anchor from body
    document.body.removeChild(a);
};
export var downloadFileByBase64 = function (fileContent, fileName) {
    var a = window.document.createElement("a");
    a.href = "data:application/octet-stream;base64," + fileContent;
    a.download = fileName || "";
    // Append anchor to body.
    document.body.appendChild(a);
    a.click();
    // Remove anchor from body
    document.body.removeChild(a);
};
//get formatted date without slash
export var formatDateWithoutSlash = function (date, dateFormat) {
    if (dateFormat === void 0) { dateFormat = "MMDDYYYY"; }
    return moment(date).format(dateFormat);
};
// TODO : enum for committee levels
export var COMMITTEE_LEVELS = {
    MAINCOMMITTEE: 1,
    SUBCOMMITTEE: 2,
    SECTION: 3,
};
export var MEMBERSHIP_REQUEST_TYPE = {
    NEWMEMBER: "New Member",
    RENEW: "Renewal",
    REINSTATE: "Reinstate",
    STUDENT_UPGRADE: "Student Upgrade",
};
export var EMAIL_TEMPLATE_NAME = {
    CHANGE_OF_EMPLOYMENT: "Change_Of_Employment_Email",
    CHANGE_OF_COMPANY_ADDRESS_EMAIL: "Change_Of_Company_Address_Email",
};
export var FORM_TYPE_ORG_UPDATE = {
    Member: "Member",
    Organization: "Organization",
};
export var canadaZipCodeFix = function (str) {
    if (str.length > 0 && str.includes("-"))
        return str.replace("-", " ");
    return str;
};
export var searchEventList = function (self, trimmedValue) {
    var eventList = self.state.eventList;
    var eventSearchList = eventList && eventList.length > 0
        ? eventList.filter(function (item) {
            return (item.EventName && item.EventName.indexOf(trimmedValue) >= 0) || (item.EventName && item.EventName.toLowerCase().indexOf(trimmedValue.toLowerCase()) >= 0);
        })
        : [];
    if (eventSearchList.length > 0 && trimmedValue.length > 0) {
        self.setState({
            isLoading: false,
            eventSearchList: eventSearchList,
            isOpen: true,
            showNoResultsMessage: eventSearchList.length === 0,
        });
    }
    else if (!trimmedValue) {
        self.setState({ eventSearchList: self.state.eventList }, function () {
            var eventSearchList = self.state.eventSearchList;
            var showNoResultsMessage = eventSearchList.length > 0 ? false : true;
            self.setState({ isLoading: false, eventSearchList: eventSearchList, isOpen: true, showNoResultsMessage: showNoResultsMessage });
        });
    }
    else if (eventSearchList.length === 0 && trimmedValue.length > 0) {
        self.setState({
            isLoading: false,
            eventSearchList: [],
            isOpen: true,
            showNoResultsMessage: eventSearchList.length === 0,
        });
    }
    else {
        self.setState({ isLoading: false, eventSearchList: [], isOpen: false });
    }
};
export var openApiPathArray = ["/master/featureflag"];
export var scrollToErrorOnFormHandler = function () {
    var fields = "input, select, textarea, .sidebar-selection-input"; // Increase Fields as per requirement.
    var currentFormSelector = document.querySelectorAll("form");
    // Wait for is-Invalid class attached into the fields.
    setTimeout(function () {
        // Get all form into single screen.
        for (var index = 0; index < currentFormSelector.length; index++) {
            if (currentFormSelector[index]) {
                var fieldsElements = currentFormSelector[index].querySelectorAll(fields);
                // Get all fields from Form references.
                for (var i = 0; i < fieldsElements.length; i++) {
                    if ((fieldsElements[i].classList && fieldsElements[i].classList.value.indexOf("is-invalid") > -1) || (fieldsElements[i].offsetParent && fieldsElements[i].offsetParent.classList && fieldsElements[i].offsetParent.classList.value.indexOf("is-invalid") > -1)) {
                        fieldsElements[i].scrollIntoView({ behavior: "smooth", block: "center" });
                        return false;
                    }
                }
            }
        }
    }, 100);
};
export var getFormattedDate = function (date, format) {
    if (format === void 0) { format = "MM/DD/YYYY"; }
    if (!date) {
        return;
    }
    return moment(date).format(format);
};
export var encodeText = function (text) {
    return window.btoa(text);
};
export var formatFileSize = function (bytes) {
    if (bytes == 0)
        return "0 Bytes";
    var k = 1024;
    var sizes = ["Bytes", "KB", "MB", "GB"];
    var i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + " " + sizes[i];
};
export var scrollToActiveItem = function () {
    var parentCurrent = document.querySelector("#render-table-for-documentlist");
    if (parentCurrent && parentCurrent.querySelectorAll("td").length > 0) {
        var fields_1 = "a"; // Increase Fields as per requirement.
        var currentFormSelector_1 = parentCurrent.querySelectorAll("td");
        // Wait for is-Invalid class attached into the fields.
        setTimeout(function () {
            // Get all form into single screen.
            for (var index = 0; index < currentFormSelector_1.length; index++) {
                if (currentFormSelector_1[index]) {
                    var fieldsElements = currentFormSelector_1[index].querySelectorAll(fields_1);
                    // Get all fields from Form references.
                    for (var i = 0; i < fieldsElements.length; i++) {
                        if ((fieldsElements[i].classList && fieldsElements[i].classList.value.indexOf("activeDocumentForDocumentInfo") > -1) || (fieldsElements[i].offsetParent && fieldsElements[i].offsetParent.classList && fieldsElements[i].offsetParent.classList.value.indexOf("activeDocumentForDocumentInfo") > -1)) {
                            fieldsElements[i].scrollIntoView({ behavior: "smooth", block: "center" });
                        }
                    }
                }
            }
        }, 100);
    }
};
export var downloadFile = function (data) {
    var url = data.DownloadURL;
    var a = document.createElement("a");
    a.href = url;
    a.setAttribute("download", data.DocumentName);
    a.setAttribute("target", "_blank");
    document.body.append(a);
    a.click();
};
export var downloadFileInSameTab = function (data) {
    var url = data.DownloadURL;
    var a = document.createElement("a");
    a.href = url;
    a.setAttribute("download", data.DocumentName);
    document.body.append(a);
    a.click();
};
export var timeLocal = function (date) {
    return moment.utc(date).local().format("hh:mm A");
};
export var getApiUniqueRequestId = function (url) {
    var uniqueRequestIds = JSON.parse(cacheManager.getItem("uniqueRequestIdForApis"));
    for (var key in uniqueRequestIds) {
        if (url === key) {
            return uniqueRequestIds[key];
        }
    }
};
export var isValidDate = function (date) {
    return date instanceof Date && !isNaN(date.getTime());
};
// Function returns date format in MM/DD/YYYY format
export var toLocalDateFormat = function (dateString) {
    if (!dateString) {
        return null;
    }
    var date = new Date(dateString);
    var month = String(date.getMonth() + 1).padStart(2, "0");
    var day = String(date.getDate()).padStart(2, "0");
    var year = date.getFullYear();
    return "".concat(month, "/").concat(day, "/").concat(year);
};
// Get All Search Options
export var getSearchAllGlobalDropDownOptions = function () {
    var searchResult = [];
    NAVIGATION && NAVIGATION.map(function (moduleItem, index) { return moduleItem[MODULES[index]] && moduleItem[MODULES[index]].map(function (subModuleItems, subIndex) { return allowPermissionForAuth(filterPermissionUrl(subModuleItems)) && subModuleItems.searchParams !== null && searchResult.push(subModuleItems.searchParams); }); });
    var sortedResult = orderBy(searchResult, ["orderIndex"], ["asc"]);
    return sortedResult;
};
export var tableSort = function (items, type, order, sortKey) {
    var sortedResults;
    var sortingArray = items.reduce(function (acc, item) {
        (Number(order) === 1) ? acc[item[sortKey] ? 1 : 0].push(item) : acc[item[sortKey] ? 0 : 1].push(item);
        return acc;
    }, [[], []]).flat();
    if (Number(order) === 1) {
        switch (type) {
            case "alphanumeric":
                sortedResults = sortingArray === null || sortingArray === void 0 ? void 0 : sortingArray.sort(function (a, b) {
                    var _a;
                    if (a && b && a[sortKey] && b[sortKey]) {
                        if (typeof a[sortKey] !== "string" || typeof b[sortKey] !== "string") {
                            return (_a = String(a[sortKey])) === null || _a === void 0 ? void 0 : _a.localeCompare(String(b[sortKey]));
                        }
                        return a[sortKey].localeCompare(b[sortKey]);
                    }
                    return 0;
                });
                break;
            case "date":
                sortedResults = sortingArray === null || sortingArray === void 0 ? void 0 : sortingArray.sort(function (a, b) {
                    if (a && b && a.PublishedDate && b.PublishedDate) {
                        return new Date(a.PublishedDate).getTime() - new Date(b.PublishedDate).getTime();
                    }
                    return 0;
                });
                break;
        }
    }
    else {
        switch (type) {
            case "alphanumeric":
                sortedResults = sortingArray === null || sortingArray === void 0 ? void 0 : sortingArray.sort(function (a, b) {
                    var _a;
                    if (a && b && a[sortKey] && b[sortKey]) {
                        if (typeof a[sortKey] !== "string" || typeof b[sortKey] !== "string") {
                            return (_a = String(b[sortKey])) === null || _a === void 0 ? void 0 : _a.localeCompare(String(a[sortKey]));
                        }
                        return b[sortKey].localeCompare(a[sortKey]);
                    }
                    return 0;
                });
                break;
            case "date":
                sortedResults = sortingArray === null || sortingArray === void 0 ? void 0 : sortingArray.sort(function (a, b) {
                    if (a && b && a.PublishedDate && b.PublishedDate) {
                        return new Date(b.PublishedDate).getTime() - new Date(a.PublishedDate).getTime();
                    }
                    return 0;
                });
                break;
        }
    }
    return sortedResults;
};
var getIncrementedDate = function (createdDate, monthsToAdd) {
    createdDate = new Date(createdDate);
    createdDate.setMonth(createdDate.getMonth() + monthsToAdd);
    return getFormattedDate(createdDate);
};
export var getCompletionDates = function (createdDate, targetInterval) {
    var startDate = '';
    var endDate = '';
    if (targetInterval === CompletionIntervalList[0].Id) {
        startDate = getIncrementedDate(createdDate, 3);
        endDate = getIncrementedDate(createdDate, 6);
    }
    else if (targetInterval === CompletionIntervalList[1].Id) {
        startDate = getIncrementedDate(createdDate, 6);
        endDate = getIncrementedDate(createdDate, 12);
    }
    else if (targetInterval === CompletionIntervalList[2].Id) {
        startDate = getIncrementedDate(createdDate, 12);
        endDate = getIncrementedDate(createdDate, 18);
    }
    else if (targetInterval === CompletionIntervalList[3].Id) {
        startDate = getIncrementedDate(createdDate, 18);
        endDate = getIncrementedDate(createdDate, 24);
    }
    return { startDate: startDate, endDate: endDate };
};
export var formatDateToMMDDYYYY = function (date, format) {
    if (date === void 0) { date = undefined; }
    if (format === void 0) { format = "MM/DD/YYYY"; }
    if (date) {
        return moment(date).format(format);
    }
    var now = new Date();
    return moment(now).format(format);
};
export var getStartDateOfCurrentMonth = function () {
    var date = new Date();
    return formatDateToMMDDYYYY(new Date(date.getFullYear(), date.getMonth(), 1));
};
export var checkIfDateValid = function (date, format) {
    if (date) {
        var sptArr = date === null || date === void 0 ? void 0 : date.split("/");
        if (sptArr !== null && sptArr.length === 3) {
            if (sptArr[2] === "0000") {
                return false;
            }
        }
    }
    return moment(date, format, true).isValid();
};
// Returns true if date1 is greater than date2
export var compareDates = function (date1, date2, checkOnlyGreaterThan, format) {
    if (checkOnlyGreaterThan === void 0) { checkOnlyGreaterThan = false; }
    if (format === void 0) { format = "MM/DD/YYYY"; }
    if (checkOnlyGreaterThan) {
        return moment(date1, format, true) > moment(date2, format, true);
    }
    return moment(date1, format, true) >= moment(date2, format, true);
};
export var validateAuditLogDates = function (values, props) {
    var errors = {};
    var currentDate = formatDateToMMDDYYYY();
    if (values.dateFrom && !checkIfDateValid(values.dateFrom, "MM/DD/YYYY")) {
        errors.dateFrom = getMessage(props.messageCodes, "2009.text");
    }
    if (values.dateTo && !checkIfDateValid(values.dateTo, "MM/DD/YYYY")) {
        errors.dateTo = getMessage(props.messageCodes, "2009.text");
    }
    if (checkIfDateValid(values.dateFrom, "MM/DD/YYYY") && checkIfDateValid(values.dateTo, "MM/DD/YYYY") && moment(values.dateFrom, "MM/DD/YYYY", true) > moment(values.dateTo, "MM/DD/YYYY", true)) {
        errors.dateFrom = getMessage(props.messageCodes, "2007.text");
    }
    return errors;
};
export var minDateForAuditLogs = '01/01/1700';
// Function to allow alpha numeric positive  characters
export var allowAlphaNumericPositiveInput = function (e) {
    if (!AlphaNumeric_Regex.test(e.key)) {
        e.preventDefault();
    }
};
export var removeLeadingZeroes = function (wkNumber) {
    return wkNumber && wkNumber.replace(/^WK0*(\d+)$/, 'WK$1');
};
export var getSubCommitte = function (subCommitte) {
    var subCommittee;
    var committees = subCommitte.split(".");
    if (committees.length == 3) {
        subCommittee = committees[1] + committees[2];
    }
    else if (committees.length == 2) {
        subCommittee = committees[1] + "00";
    }
    else {
        subCommittee = "0000";
    }
    return subCommittee;
};
export default {
    Blank_Space_Check_Regex: Blank_Space_Check_Regex,
    AlphaNumeric_Regex: AlphaNumeric_Regex,
    Name_Regex: Name_Regex,
    PositiveRegex: PositiveRegex,
    Number_Regex: Number_Regex,
    getCookie: getCookie,
    getFileType: getFileType,
    getCurrentlyShowingItemsInGrid: getCurrentlyShowingItemsInGrid,
    scrollToTopOfPage: scrollToTopOfPage,
    utcDateTimeToLocalDateTime: utcDateTimeToLocalDateTime,
    utcDateTimeSecondToLocalDateTime: utcDateTimeSecondToLocalDateTime,
    utcDateTimeSecondToDate: utcDateTimeSecondToDate,
    extractValueFromUrl: extractValueFromUrl,
    getMessage: getMessage,
    getSortOrderFromHeaderClick: getSortOrderFromHeaderClick,
    resetHeaderSortOrder: resetHeaderSortOrder,
    formatListDataForAutoSearch: formatListDataForAutoSearch,
    generateUUID: generateUUID,
    generateUUIDForAutofill: generateUUIDForAutofill,
    validateNavigationFromUrl: validateNavigationFromUrl,
    filterPermissionUrl: filterPermissionUrl,
    allowPermissionForAuth: allowPermissionForAuth,
    routeNavigationUrl: routeNavigationUrl,
    getModulesAdditionalPrivilege: getModulesAdditionalPrivilege,
    azureAdFetchCode: azureAdFetchCode,
    validateNavigationPermission: validateNavigationPermission,
    setLoginDataToCacheManager: setLoginDataToCacheManager,
    isReactAppAuthorized: isReactAppAuthorized,
    clearAzureAdAuthCodeAction: clearAzureAdAuthCodeAction,
    getCurrentUserEmail: getCurrentUserEmail,
    cookiesManager: cookiesManager,
    isTokenExistInCookies: isTokenExistInCookies,
    noCookiesRedirectToLogin: noCookiesRedirectToLogin,
    getMinutes: getMinutes,
    getUserAgent: getUserAgent,
    commaSeperator: commaSeperator,
    encodeStreamData: encodeStreamData,
    downloadByUrl: downloadByUrl,
    downloadDocument: downloadDocument,
    downloadFileByBase64: downloadFileByBase64,
    formatDateWithoutSlash: formatDateWithoutSlash,
    searchEventList: searchEventList,
    openApiPathArray: openApiPathArray,
    scrollToErrorOnFormHandler: scrollToErrorOnFormHandler,
    getFormattedDate: getFormattedDate,
    encodeText: encodeText,
    formatFileSize: formatFileSize,
    scrollToActiveItem: scrollToActiveItem,
    downloadFile: downloadFile,
    timeLocal: timeLocal,
    getApiUniqueRequestId: getApiUniqueRequestId,
    getSearchAllGlobalDropDownOptions: getSearchAllGlobalDropDownOptions,
    tableSort: tableSort,
    getCompletionDates: getCompletionDates,
    formatDateToMMDDYYYY: formatDateToMMDDYYYY,
    getStartDateOfCurrentMonth: getStartDateOfCurrentMonth,
    toUTCDateAndTime: toUTCDateAndTime,
    allowAlphaNumericPositiveInput: allowAlphaNumericPositiveInput,
    getSubCommitte: getSubCommitte
};
